<template>
  <ur-page-container class="msp" title="보장설계 계약현황" :show-title="false" :topButton="true">
    
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ct-wrap pb82">
      <ur-box-container direction="column" alignV="start" class="">
        <ur-box-container direction="column" align-v="start" class="ns-info-area bgcolor-1 pb20">
          <ur-box-container alignV="start" componentid="" direction="column" >
            <ul class="terms-list-area--gray">
              <li>
                기간별 분석의 경우 피보험자 기준으로 제공됩니다.
              </li>
              <li>
                신용정보원에서 제공하는 정보는 기간별 분석에서  '주보험' 기간으로만 예시하였고, 실제 보장기간은 다를 수 있습니다.
              </li>
            </ul>
          </ur-box-container>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum mt6">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <span class="sum">총<span class="count">{{$commonUtil.numberWithCommas(contCnt)}}</span>건</span>
                <mo-button class="black h28px" @click="fn_openMSPCT116P">타사정보 확인</mo-button>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="">
          <mo-list-item v-for="(list,idx) in tableData" :key="idx" class="bd-b-Ty1 plr24"> 
            <msp-expand btn-area-first title-first expand-only-btn class="mo-list-expand" btn-area-class="fexTy3-1">
              <template #title>
                <div class="list-item__contents pt20 pb20">
                  <div class="list-item__contents">
                    <div class="fexTy5">
                      <span class="fs19rem fwb" :class="{'crTy-purple1' : list.productNamePrefix === '(신)', 'crTy-bk3' : list.productNamePrefix === '(타)'}">{{list.productNamePrefix}}</span>
                      <span class="fs19rem fwb" :class="{'lnkTy1' : list.productNamePrefix === '(신)' || list.productNamePrefix === '(타)'}" @click="fn_openMSPCT115P(list)">{{(list.productName)}}</span>
                    </div>
                    <div class="mt4">
                      <span>{{list.company}}</span>
                    </div>
                    <div class="mt8">
                      <span>보험료</span>
                      <span class="fs16rem crTy-blue3 ml10 fwb">{{list.payment}}원</span>
                      <span class="fs16rem ml8">({{list.paymentPattern}}, {{list.paymentCount}})</span>
                    </div>
                    <div class="mt4">
                      <span class="">계약자</span>
                      <!-- <span class="fs16rem ml8 lnkTy1" @click="fn_OpenCustInfoBs('', list.contractor)">{{list.contractor}}</span> -->
                      <span class="fs16rem ml8">{{list.contractor}}</span>
                      <em></em>
                      <span>주피</span>
                      <!-- <span class="fs16rem ml8 lnkTy1" @click="fn_OpenCustInfoBs(list.chnlCustId, list.main)">{{list.main}}</span> -->
                      <span class="fs16rem ml8">{{list.main}}</span>
                      <em v-if="!$bizUtil.isEmpty(list.sub)"></em>
                      <span v-if="!$bizUtil.isEmpty(list.sub)">종피</span>
                      <!-- <span class="fs16rem ml8 lnkTy1" @click="fn_OpenCustInfoBs('', list.sub)">{{list.sub}}</span> -->
                      <span class="fs16rem ml8">{{list.sub}}</span>
                    </div>
                    <div class="mt4">
                      <span>계약</span>
                      <span class="fs16rem ml8">{{list.contractDate}}</span>
                      <em></em>
                      <span>만기</span>
                      <span class="fs16rem ml8">{{list.endDate}}</span>
                    </div>
                    <div class="mt4">
                      <span>납입</span>
                      <span class="fs16rem ml8">{{list.paymentDate}}</span>
                    </div>
                  </div>
                </div> 
              </template>
              <template #btn>
                <mo-button class="link-arrow down mt24 dsn" :class="{'dsD': list.chartData != null}"></mo-button>
              </template>
              <template #content v-if="list.chartData != null">
                <div class="list-item__contents margin--full">
                  <div class="list-item__contents__info gray-box pal2024">
                    <div class="fexTy4">
                      <span class="fs12rem crTy-blue3">납입</span>
                      <span class="fs12rem ml6">{{fn_getPayPeriod(list.chartData)}}</span>
                      <span class="fs12rem ml10 mr10">·</span>
                      <span class="fs12rem crTy-green1">보장</span>
                      <span class="fs12rem ml6">{{fn_getCovanPeriod(list.chartData)}}</span>
                    </div>
                    <div class="bar-chart--ty3 mt10">
                      <!-- 보장기간 -->
                      <div class="fill-bar" :style="{width:'calc('+fn_chartBar1(list.chartData)+'%)'}"></div> 
                      <!-- 납입기간 -->
                      <div class="fill-bar2" :style="{width:'calc('+fn_chartBar2(list.chartData)+'%)'}"></div>
                    </div>
                    <div class="fexTy3 mt4 grid_minmax--ty1">
                      <template v-for="(item, idx) in ageList">
                        <span class="fs12rem crTy-bk7" :key="idx" v-if="idx < 5 || idx == ageList.length-1">{{item}}</span>
                        <span class="fs12rem crTy-bk7" :key="idx" v-else></span>
                      </template>
                    </div>
                    <div class="fexTy3 mt10">
                      <span class="fs14rem crTy-bk7">총 납입</span>
                      <span class="fs16rem crTy-blue3 fwb">{{list.totalPayment}}원</span>
                    </div>
                  </div>
                </div>
              </template>
            </msp-expand>
          </mo-list-item>        
          
        </ur-box-container>
      </ur-box-container>
    </ur-box-container> 
    <!-- Content 영역 end -->

  </ur-page-container>
</template>
<script>
import UrBoxContainer from '@/_uidev/containers/box/urBoxContainer.vue'
import CtConstants from '@/config/constants/ctConstants' // 컨설팅 상수

import MSPCT115P from '@/ui/ct/MSPCT115P'
import MSPCT116P from '@/ui/ct/MSPCT116P'

require('@/assets/lib/rMateChartH5/Assets/Css/rMateChartH5.css')
require('@/assets/lib/rMateChartH5/JS/rMateScrollChartH5.js')
require('@/assets/lib/rMateChartH5/LicenseKey/rMateChartH5License.js')
require('@/assets/lib/rMateChartH5/JS/rMateChartVar.js')

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCT112D",
    screenId: "MSPCT112D",
    components: {
        UrBoxContainer
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        custAge: Number(this.getStore('ctStore').getters.getCustBaseInfo.data.age),
        wlifeAge: CtConstants.wlifeAge,
        ageList: [],
        contCnt: 0,
        tableDataCopy: [],
        tableData: [],
        padCylItems: [
          { key: '99', value: '일시납' },
          { key: '12', value: '연납' },
          { key: '06', value: '6월납' },
          { key: '04', value: '4월납' },
          { key: '05', value: '5월납' },
          { key: '03', value: '3월납' },
          { key: '02', value: '2월납' },
          { key: '01', value: '월납' }
        ],
        contData: [
        ]
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
    },
    mounted() {
      this.$bizUtil.insSrnLog(this.$options.screenId)
      // this.changeData()
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    },
    watch: {
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      // 세대원 타사정보확인 팝업 열기
      fn_openMSPCT116P(){
        let lp_Vm = this  
        this.popupCT116P = this.$bottomModal.open(MSPCT116P, {
          properties: {
            pPage: 'MSPCT112D'
          },
          listeners: {
            onPopupSearch: () => {
              lp_Vm.$bottomModal.close(lp_Vm.popupCT116P)
            },
            onPopupClose: (reload) => {
              if(reload){
                this.$emit('reloadData')
              }
              lp_Vm.$bottomModal.close(lp_Vm.popupCT116P)
            }
          }
        },
        {
          properties: {
            noHeader: true
          }
        })
      },
      // 계약별보장내역
      fn_openMSPCT115P(item) {
        // 타사증권팝업호출
        // if(item.productNamePrefix === '(타)'){
        if (!item.tcomYn) {
        //   this.$parent.$refs.TSSCT005P.scrpFlag = false // 초기화
        //   this.$parent.$refs.TSSCT005P.sno = param.contNo
        //   this.$parent.$refs.TSSCT005P.chnlCustId = param.chnlCustId

        //   if (param.gtplOtcomPrdtInptScCd === '1') { // 진입구분값 reg:신규등록 upd:수정 unReg:미등록 수정 scrp:스크래핑 kcis:신용정보원(수정)
        //     this.$parent.$refs.TSSCT005P.entryType = 'upd'
        //   } else if (param.gtplOtcomPrdtInptScCd === '3') {
        //     this.$parent.$refs.TSSCT005P.entryType = 'upd'
        //     this.$parent.$refs.TSSCT005P.scrpFlag = true
        //   } else if (param.gtplOtcomPrdtInptScCd === '4') {
        //     this.$parent.$refs.TSSCT005P.entryType = 'kcis'
        //   } else {
        //     this.$parent.$refs.TSSCT005P.entryType = 'unReg'
        //   }

        //   this.$parent.$refs.TSSCT005P.showPopup = true
        // }
        }
        console.log('타사증권 팝업호출 item >>> ', item)
        
        if (!item.tcomYn) {
          let lp_Vm = this  
          this.popupCT115P = this.$bottomModal.open(MSPCT115P, {
            properties: {
              pPage: 'MSPCT112D',
              pData: lp_Vm.MSPCT115PData,
              pItem: item
            },
            listeners: {
              onPopupSearch: () => {
                lp_Vm.$bottomModal.close(lp_Vm.popupCT115P)
              },
              onPopupClose: () => {
                lp_Vm.$bottomModal.close(lp_Vm.popupCT115P)
              },
              onPopupSaveClose: (flag) => {
                if(flag) {
                  this.$emit('reloadData')
                }
                lp_Vm.$bottomModal.close(lp_Vm.popupCT115P)
              }
            }
          },
          {
            properties: {
              noHeader: true
            }
          })
        }
      },
      fn_chartBar1(item) {
        // console.log('item.expAge >> ', item.expAge, '보장기간 >>>', Math.round((item.expAge-item.custCurAge)/(item.wlifeAge-item.custCurAge)*100))
        return Math.round((item.expAge-item.custCurAge)/(item.wlifeAge-item.custCurAge)*100)
      },
      fn_chartBar2(item) {
        // console.log('item.padCmpltAge >> ', item.padCmpltAge, '납입기간 >>>', Math.round((item.padCmpltAge-item.custCurAge)/(item.wlifeAge-item.custCurAge)*100))
        return Math.round((item.padCmpltAge-item.custCurAge)/(item.wlifeAge-item.custCurAge)*100)
      },
      /*********************************************
       * Function명 : fn_OpenCustInfoBs
       * 설명       : 고객상세 팝업 연동
       ******************************************************************************/
      fn_OpenCustInfoBs (chnlCustId, custNm) {
        let lv_Vm = this
        let nmUiObject = {}

        nmUiObject = {
          chnlCustId : chnlCustId, //고객채널 ID
          parentId   : 'MSPCT112D', //화면ID+
          cnsltNo : this.getStore('userInfo').getters.getUserInfo.userId,
          custNm : custNm
        }

        lv_Vm.$cmUtil.fn_OpenCustNmUiCard(lv_Vm, nmUiObject,(modalId)=>{
          return
        })
      },
      // 납입기간
      fn_getPayPeriod(data) {
        // console.log('납입기간 >>>', data.padCmpltAge - data.custCurAge)
        return (data.padCmpltAge - data.custCurAge < 0 ? '납입완료' : (data.padCmpltAge - data.custCurAge) + '년')
      },
      // 보장기간
      fn_getCovanPeriod(data) {
        // console.log('보장기간 >>>', data.expAge - data.custCurAge)
        return (data.expAge - data.custCurAge < 0 ? 0 : data.expAge - data.custCurAge)  + "년"
      },
      changeData () {
        this.tableData = []
        this.tableDataCopy = []
        this.contData = [].concat(this.getStore('ctStore').getters.getHoshContData.data)
        console.log('this.contData ::: ', this.contData.length, this.contData)
        this.contCnt = this.contData.length

        this.MSPCT115PData = []
        // 그리드 데이터 셋팅
        for ( let i in this.contData ) {
          let lv_tableData = {}
          lv_tableData.underline = true // 당사여부
          lv_tableData.productName = this.fn_prdtNmSet(this.contData[i].prdtNm, this.contData[i].padStatCd) // 보험명
          lv_tableData.endDate = this.fn_setExpYmd(this.contData[i].expYmd) // 만기년월
          lv_tableData.contractor = this.contData[i].contrNm // 계약자
          lv_tableData.main = this.contData[i].mnisdNm // 주피
          lv_tableData.sub = this.contData[i].aisdNm // 종피
          lv_tableData.contractDate = this.$bizUtil.dateYyMmFormat(this.contData[i].contYmd) // 계약년월
          lv_tableData.company = this.contData[i].insrCoNm // 가입회사
          lv_tableData.paymentDate = this.$bizUtil.dateYyMmFormat(this.contData[i].padCmpltYmd) // 납입완료
          lv_tableData.paymentPattern = this.fn_getPadCylItem(this.contData[i].padCylCd) // 납입주기
          lv_tableData.payment = this.$commonUtil.numberWithCommas(this.contData[i].smtotPrm.longAmount) // 보험료
          lv_tableData.paymentCount = this.$commonUtil.numberWithCommas(Number(this.contData[i].curPadNts)) + '회/총 ' + this.$commonUtil.numberWithCommas(Number(this.contData[i].whlPadNts)) + '회' // 납입횟수
          lv_tableData.totalPayment = this.$commonUtil.numberWithCommas(this.contData[i].alpadTotPrm.longAmount) // 납입하신 보험료 계
          lv_tableData.furTotPayment = this.$commonUtil.numberWithCommas(this.contData[i].padParngTotPrm.longAmount) // 향후 납입할 보험료 계
          lv_tableData.tcomYn = this.contData[i].tcomYn // 당사여부
          lv_tableData.contNo = this.contData[i].contNo // 계약번호
          lv_tableData.gtplOtcomPrdtInptScCd = this.contData[i].gtplOtcomPrdtInptScCd // 보장플랜타사상품입력구분코드
          lv_tableData.chnlCustId = this.contData[i].chnlCustId // 채널고객

          if (this.contData[i].gtplOtcomPrdtInptScCd === '1' || this.contData[i].gtplOtcomPrdtInptScCd === '2') {
            lv_tableData.productNamePrefix = '(타)'
            this.MSPCT115PData.push(this.contData[i])
            // this.MSPCT115PData.push(lv_tableData)
          } else if (this.contData[i].gtplOtcomPrdtInptScCd === '4') {
            lv_tableData.productNamePrefix = '(신)'
            this.MSPCT115PData.push(this.contData[i])
            // this.MSPCT115PData.push(lv_tableData)
          }
          if (lv_tableData.company === '삼성생명') lv_tableData.pri = 0
          else lv_tableData.pri = 1
          this.tableDataCopy.push(lv_tableData)
        }
        // 2-1. 회사명(타사 가나다 순)(오름차순)
        // 2-2. 피보험자 성함(가나다 순)(오름차순)
        // 2-3. 계약일(최신순)(내림차순)
        // 2-4. 보험명(가나다)(오름차순)
        // 2-5. 보험료(고액순)(내림차순)
        this.tableDataCopy.sort((a, b) =>
          a.company.localeCompare(b.company, 'en')
          || a.main.localeCompare(b.main, 'en')
          // || b.contractDate.split('.').join().substr(0, 6) - a.contractDate.split('.').join().substr(0, 6)
          || b.contractDate.split('.').join().substr(0, 6).localeCompare(a.contractDate.split('.').join().substr(0, 6), undefined, {numberic: true})
          || a.productName.localeCompare(b.productName, 'en')
          || b.payment - a.payment
          // || b.payment.localeCompare(a.payment, undefined, {numberic: true})
        )
        this.tableDataCopy.sort((a, b) =>
          a.pri - b.pri
        )
        this.tableData = [].concat(this.tableDataCopy)
        console.log('this.tableData >>> ', this.tableData)

        this.fn_makeChartData()

        console.log('this.tableData chartData 추가 >>> ', this.tableData)
        
      },
      // 상품명 표시 (1. 계약,일부 납면 문구 표시 2. 한자변환)
      fn_prdtNmSet: function (prdtNm, padStatCd) {
        return this.$bizUtil.ctUtils.fn_CovanPrdtNmSet(prdtNm, padStatCd)
      },
      // 만기년월 표시
      fn_setExpYmd: function (expYmd) {
        // console.log('expYmd' + expYmd)
        if (!expYmd) return ''
        expYmd = expYmd.toString().replace(/-/gi, '')

        if (expYmd.substr(0, 4) === '9999') {
          return '종신'
        } else {
          return this.$bizUtil.dateYyMmFormat(expYmd)
        }
      },
      // 납입주기 코드 -> 명으로 표시
      fn_getPadCylItem: function (padCylCd) {
        // console.log('padCylCd' + padCylCd)
        return this.padCylItems.find(function (item) { return item.key === padCylCd }).value
      },
      fn_makeChartData: function () {
        let lv_Vm = this
        let tmpContData = []
        let tcompKcisSc = lv_Vm.getStore('ctStore').getters.getTcompKcisSc // 당사,신정원 정보출처구분(1:당사, 2:신정원)

        if(tcompKcisSc === '1'){
          tmpContData = [].concat(this.getStore('ctStore').getters.getContData.data)
        } else {
          tmpContData = [].concat(this.getStore('ctStore').getters.getHoshContData.data)
        }

        this.ageList = []
        let pass = true
        let cnt = 0
        while(pass){
          console.log(cnt)
          let age = lv_Vm.custAge + (10*cnt)
          if(age < lv_Vm.wlifeAge) {
            lv_Vm.ageList.push(age)
          }else{
            lv_Vm.ageList.push('종신')
            pass = false
          }
          cnt++
        }

        console.log('this.ageList >>> ', this.ageList)

        // 처음 들어온 값이 가장 아래로 나오는 차트의 특성상 뒤에서부터 for문 돌림
        // for (let i = (tmpContData.length-1); i >= 0; i--) {
        for(let i in tmpContData) {
          let tmpPadCmpltAge = tmpContData[i].padCmpltYmd.substring(0,4) - new Date().getFullYear() + this.custAge, //납입완료시점나이
              tmpExpAge = tmpContData[i].expYmd.substring(0,4)=='9999' ? this.wlifeAge : tmpContData[i].expYmd.substring(0,4) - new Date().getFullYear() + this.custAge //보장나이
              // tmpPadCmpltAgeForChart = 0, //차트를 그리기위한 값
              // tmpExpTermForChart = 0, //차트를 그리기위한 값
              // tmpMdfcVal = 0  //차트를 그리기위한 조정값

          // if(tmpPadCmpltAge < this.custAge || (tmpPadCmpltAge > this.custAge && tmpPadCmpltAge == tmpExpAge)) {
          //   tmpPadCmpltAgeForChart = this.custAge
          //   tmpExpTermForChart = tmpExpAge-tmpPadCmpltAge-(this.custAge-tmpPadCmpltAge)
          // } else {
          //   tmpPadCmpltAgeForChart = tmpPadCmpltAge
          //   tmpExpTermForChart = tmpExpAge - tmpPadCmpltAge
          // }

          let tmpObj = {
            contNo : tmpContData[i].contNo, // 계약번호
            gtplOtcomPrdtInptScCd : tmpContData[i].gtplOtcomPrdtInptScCd, // 타사상품구분코드
            prdtNm : this.$bizUtil.ctUtils.fn_CovanPrdtNmSet(tmpContData[i].prdtNm, tmpContData[i].padStatCd), // 상품명 표시 (1. 계약,일부 납면 문구 표시 2. 한자변환)
            padCmpltAge : tmpPadCmpltAge, // 납입완료나이
            expAge : tmpExpAge, // 보장만료나이
            // padCmpltAgeForChart : tmpPadCmpltAgeForChart, // 차트를 그리기위한 값 (납입완료값 시작지점 ~ 납입완료값 종료지점)
            // expTermForChart : tmpExpTermForChart, // 차트를 그리기위한 값 (납입완료값 종료지점 ~ 보장만료값 종료지점)
            tcomYn : tmpContData[i].tcomYn,
            custCurAge : this.custAge,  // 고객현재나이
            wlifeAge : this.wlifeAge,  // 종신나이
            chnlCustId: tmpContData[i].chnlCustId
          }
          // console.log('tmpObj ==============>>> ', tmpObj)

          // this.chartData.push(tmpObj)

          this.tableData.forEach(item => {
            if(item.contNo == tmpObj.contNo){
              item.chartData = tmpObj
            }
          })
        }

      }


    }
  }
</script>
<style scoped>
</style>